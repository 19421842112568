import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonSegment,
  IonSegmentButton,
  IonModal,
  withIonLifeCycle,
  IonLoading,
} from "@ionic/react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Checkout/Navbar/Navbar";
import NavbarV2 from "../../../../components/Checkout/Navbar/NavbarV2";
import "./Checkout.css";

import React, { useMemo } from "react";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import {
  arrowForwardCircle,
  arrowForwardOutline,
  cart,
  checkmark,
  chevronDown,
  close,
  closeOutline,
  lockClosed,
  lockClosedOutline,
  navigateCircleOutline,
} from "ionicons/icons";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import StringUtils from "../../../../lib/StringUtils";
import MealOptionCard from "../../../../components/MealOptionCard";
import logo from "../../../../assets/img/logo/pln_logo.svg";
import applePay from "../../../../assets/img/icons/apple_pay_symbol.svg";
import PaymentUtils from "../../../../lib/PaymentUtils";
import visaCard from "../../../../assets/img/icons/visa_card.svg";
import mastercardCard from "../../../../assets/img/icons/mastercard_card.svg";
import amexCard from "../../../../assets/img/icons/amex_card.svg";
import discoverCard from "../../../../assets/img/icons/discover_card.svg";
import moment, { Moment } from "moment-timezone";
import SegmentUtils from "../../../../lib/SegmentUtils";
import SEOHeaders from "../../../../components/SEOHeaders";
import stripeTrust from "../../../../assets/img/icons/stripe-badge-transparent.png";
import stripeLogo from "../../../../assets/img/icons/powered-by-stripe.svg";
import {
  Elements,
  ElementsConsumer,
  ExpressCheckoutElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeExpressCheckoutElementConfirmEvent,
  loadStripe,
} from "@stripe/stripe-js";
import PostHogUtils from "../../../../lib/PostHogUtils";
import MealDetailModal from "../../../../components/MealDetailModal";

let stripeOptions = {
  cssSrc: "https://fonts.googleapis.com/css?family=Karla",
  loader: "always",
};

class StripeCheckoutForm extends React.Component<{
  stripe: any;
  elements: any;
  firstBillDate: any;
  email?: any;
  phone?: any;
  onComplete?: any;
  stripeCustomerID: string;
  history: any;
  coupon: any;
  referral: any;
  plan: any;
  selectedMeals: any;
  lead: any;
  store: any;
}> {
  state: any = {};

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.email != this.props.email &&
      this.props.email &&
      !this.state.email
    ) {
      this.setState({
        email: this.props.email,
      });
    }

    if (
      prevProps?.phone != this.props.phone &&
      this.props.phone &&
      !this.state.phone
    ) {
      const res: any = StringUtils.formatPhoneNumberWhileTyping(
        this.props.phone
      );

      this.setState({
        phone: res?.value,
        phoneReadable: res?.formatted,
      });
    }
  }

  componentDidMount() {
    if (this.props.email && !this.state.email) {
      this.setState({
        email: this.props.email,
      });
    }

    if (this.props.phone && !this.state.phone) {
      const res: any = StringUtils.formatPhoneNumberWhileTyping(
        this.props.phone
      );

      this.setState({
        phone: res?.value,
        phoneReadable: res?.formatted,
      });
    }
  }

  /**
   * Sets the error message
   * @param error
   */
  setError(error: string) {
    this.setState({ error });

    setTimeout(() => {
      this.setState({ error: null });
    }, 5000);
  }

  isPreorder() {
    if (this.props.store) {
      let firstBill = moment(
        this.props.store?.operations?.storeOpening?.firstBillDate
      );
      let now = moment();

      if (!now.isAfter(firstBill)) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param asMoment
   * @returns any
   */
  /**
   *
   * @param asMoment
   * @returns any
   */
  getFirstBillingDate(asMoment = false) {
    let firstBillDate = moment(
      this.state.firstBillDate
        ? this.state.firstBillDate
        : this.props.store?.operations?.storeOpening?.firstBillDate
    );

    let now = moment();

    now.tz("America/New_York");

    // Check to see if an override is present for the first bill date.
    if (this.state.firstBillDate) {
      let overrideMoment = moment(this.state.firstBillDate);
      let fbOverride = overrideMoment.isBefore(now);

      if (
        this.isPreorder() &&
        firstBillDate.isBefore(
          moment(this.props.store?.operations?.storeOpening?.firstBillDate)
        )
      ) {
        firstBillDate = moment(
          this.props.store?.operations?.storeOpening?.firstBillDate
        );

        if (asMoment) {
          return firstBillDate;
        }

        return firstBillDate.format("MMMM DD, YYYY");
      }

      if (this.props.store?.operations?.blackoutDates?.length) {
        while (
          this.props.store?.operations?.blackoutDates.indexOf(
            overrideMoment.format("YYYY-MM-DD")
          ) >= 0
        ) {
          overrideMoment.add(1, "week");
        }
      }

      // Check to see if the overrided date is before the first bill date for the store overall
      if (fbOverride) {
        StorageUtils.set("checkout_first_bill_date", "");
      } else {
        if (asMoment) {
          return overrideMoment;
        }

        return overrideMoment.format("MMMM DD, YYYY");
      }
    }

    if (this.isPreorder()) {
      if (asMoment) {
        return firstBillDate;
      }

      return firstBillDate.format("MMMM DD, YYYY");
    }

    let next = moment();

    if (now.day() <= 3 && now.minutes() <= 59 && now.hours() <= 23) {
      next.day(6).startOf("day");
    } else {
      next.add(1, "week").day(6).startOf("day");
    }

    if (this.state.store?.operations?.blackoutDates?.length) {
      while (
        this.state.store?.operations?.blackoutDates.indexOf(
          next.format("YYYY-MM-DD")
        ) >= 0
      ) {
        next.add(1, "week");
      }
    }

    if (asMoment) {
      return next;
    }

    return next.format("MMMM DD, YYYY");
  }

  async signUp(result: any) {
    this.setState({ submitting: true });

    let opaqueData = {
      provider: "stripe",
      stripeCustomerID: result?.stripeCustomerID,
      stripePaymentMethod: result?.setupIntent?.payment_method,
    };

    // 2) Submit card information
    let billing = {
      name: this.state.name,
      address_1: this.state.address1 ? this.state.address1 : "",
      address_2: this.state.address2 ? this.state.address2 : "",
      city: this.state.city ? this.state.city : "",
      state: this.state.state ? this.state.state : "",
      zip_code: this.state.zipCode ? this.state.zipCode : "",
      email: this.state.email,
      phone: this.state.phone,
    };

    let shipping = billing;

    let coupon = this.props.coupon ? this.props.coupon.code : null;
    let referredBy = this.props.referral ? this.props.referral?.code : null;

    let meals = this.props.selectedMeals?.length
      ? this.props.selectedMeals.map((m: any) => {
          return {
            _id: m?._id,
            planTypeID: m?.planTypeID,
            quantity: m?.quantity,
          };
        })
      : [];

    const chooseLater = (await StorageUtils.get("checkout_chooseLater"))
      ? true
      : false;

    const firstBillDateMoment: any = this.getFirstBillingDate(true);

    API.checkout(
      this.props.lead._id,
      this.props.plan,
      meals,
      opaqueData,
      billing,
      shipping,
      coupon,
      referredBy,
      true,
      firstBillDateMoment.format("YYYY-MM-DD"),
      chooseLater
    )
      .then(
        async (res: any) => {
          await StorageUtils.set("checkout_name", billing?.name);
          await StorageUtils.set(
            "checkout_firstBillDate",
            firstBillDateMoment.format("YYYY-MM-DD")
          );

          console.log(
            (this.props.plan?.unitPrice +
              (this.props.plan?.deliveryFee
                ? this.props.plan?.deliveryFee
                : 0)) /
              100
          );

          SegmentUtils.track("Signup Completed", {
            affiliation: "online",
            choose_later: chooseLater ? true : false,
            coupon_code: coupon?.code ? coupon?.code : "",
            coupon_id: coupon?._id ? coupon?._id : "",
            coupon_terms: coupon?.terms ? coupon?.terms : "",
            first_bill_date: firstBillDateMoment.toDate().toISOString(),
            store_id: this.props.store?._id,
            store_name: this.props.store?.name,
            referral_code: referredBy?.code ? referredBy?.code : "",
            value: Constants.CONVERSION_VALUES.NEW_MEMBER,
            products: [
              {
                product_id: this.props.plan?._id,
                brand: "Project LeanNation",
                category: "Meal Plans",
                category1: this.props.plan?.planType?.name,
                category2: this.props.plan?.procurement,
                category3: this.props.plan?.frequency,
                name: this.props.plan?.name,
                price:
                  this.props.plan?.unitPrice +
                  (this.props.plan?.deliveryFee
                    ? this.props.plan?.deliveryFee
                    : 0) /
                    100,
                quantity: 1,
                sku: this.props.plan?.code,
              },
            ],
          });

          this.props.history.push("/start/checkout/complete");
        },
        (err: any) => {
          SegmentUtils.track("Signup Error", {
            error_code: "online_checkout_error",
            error_message: err?.response?.message
              ? err?.response?.message
              : "Unknown error occurred.",
          });

          this.setError(
            err?.response?.data?.message ??
              "Unable to process your order. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    //console.log(event);

    this.setState({
      submitting: true,
    });

    try {
      event.preventDefault();
    } catch (e) {
      console.error(e);
    }

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      SegmentUtils.track("Payment Info Error", {
        checkout_id:
          (this.state.lead?.userID
            ? this.state.lead?.userID
            : this.state.lead?._id) +
          "_cart_" +
          moment().format("YYYY-MM-DD"),
        payment_method: "",
        error_code: "",
        error_message: "Stripe and/or stripe elements not loaded",
      });

      SegmentUtils.track("Signup Error", {
        error_code: "online_checkout_error",
        error_message: `Payment method error: Stripe and/or stripe elements not loaded`,
      });

      return;
    }

    let params: any = {
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        //return_url: window.location.href,
      },
      redirect: "if_required",
    };

    if (event?.elementType != "expressCheckout") {
      params.confirmParams.payment_method_data = {
        billing_details: {
          name: this.state.name,
        },
      };
    }

    let result = await stripe.confirmSetup(params);

    if (result.error || !result?.setupIntent?.payment_method) {
      // Show error to your customer (for example, payment details incomplete)
      //console.log(result.error.message);

      this.setError(
        "We received the following payment error: " +
          (result.error.message
            ? result.error.message
            : "Unknown error, please try again.")
      );

      SegmentUtils.track("Payment Info Error", {
        checkout_id:
          (this.state.lead?.userID
            ? this.state.lead?.userID
            : this.state.lead?._id) +
          "_cart_" +
          moment().format("YYYY-MM-DD"),
        payment_method: "",
        error_code: "",
        error_message: result.error.message
          ? result.error.message
          : "Unknown error",
      });

      SegmentUtils.track("Signup Error", {
        error_code: "online_checkout_error",
        error_message: `Payment method error: ${
          result.error.message ? result.error.message : "Unknown error"
        }`,
      });

      this.setState({
        submitting: false,
      });

      return;
    }

    if (typeof this.props?.onComplete == "function") {
      this.props.onComplete({
        email: this.state.email,
        phone: this.state.phone,
        stripeResult: result,
      });
    }

    result.stripeCustomerID = this.props.stripeCustomerID;

    this.signUp(result);
  };

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          this.handleSubmit(e);
        }}
      >
        <div className="mt-md-2">
          {!this.state.expressReady && (
            <>
              <IonSkeletonText
                animated={true}
                style={{
                  width: "100%",
                  height: 52,
                  marginTop: -4,
                  marginBottom: -4,
                  borderRadius: 6,
                }}
              ></IonSkeletonText>
            </>
          )}
          <ExpressCheckoutElement
            className={`pln-expresscheckout ${
              this.state.expressReady && "ready"
            }`}
            onReady={() => {
              let element = this.props.elements.getElement("expressCheckout");

              try {
                //console.log(element?._component?.style);

                element._component.style.transition = null;
              } catch (e) {
                console.error(e);
              }

              this.setState({
                expressReady: true,
              });
            }}
            onConfirm={(event: StripeExpressCheckoutElementConfirmEvent) => {
              //console.log("confirm", event, this.props.stripe);

              let statePayload: any = {
                expressCheckout: true,
              };

              if (event?.billingDetails?.name) {
                statePayload.name = event?.billingDetails?.name;
              }

              if (event?.billingDetails?.address?.line1) {
                statePayload.address1 = event?.billingDetails?.address.line1;
              }

              if (event?.billingDetails?.address?.line2) {
                statePayload.address2 = event?.billingDetails?.address.line2;
              }

              if (event?.billingDetails?.address?.city) {
                statePayload.city = event?.billingDetails?.address.city;
              }

              if (event?.billingDetails?.address?.state) {
                statePayload.state = event?.billingDetails?.address.state;
              }

              if (event?.billingDetails?.address?.postal_code) {
                statePayload.zipCode =
                  event?.billingDetails?.address.postal_code;
              }

              this.setState(statePayload, () => {
                this.handleSubmit(event);
              });

              //this.state.stripe.confirmPayment();

              //this.state.stripe.getCardToken();
            }}
          ></ExpressCheckoutElement>
        </div>
        <div
          className="mt-4 pt-4"
          style={{
            borderTop: "1px solid var(--midlighter)",
          }}
        >
          <h4 className="font-bold text-xl text-left mb-1 text-dark">
            Or Manually Enter Information
          </h4>
          <p className="small text-left mt-0 mb-4" style={{ fontSize: 15 }}>
            Enter your contact & payment information below
          </p>

          <div className="mt-3">
            <div className="relative">
              <label
                htmlFor="name"
                className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
              >
                Name
                <span className="text-red-600 ml-[2px]">*</span>
              </label>
              <input
                type="text"
                name="name"
                autoComplete="name"
                value={this.state.name}
                className="block w-full text-base sm:text-lg rounded outline-0 border border-gray-100 px-3 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                placeholder="Your Name"
                onChange={(event: any) => {
                  this.setState({
                    name: event?.target?.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="relative">
              <label
                htmlFor="email"
                className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
              >
                Email
                <span className="text-red-600 ml-[2px]">*</span>
              </label>
              <input
                type="email"
                name="email"
                autoComplete="email"
                value={this.state.email}
                className="block w-full text-base sm:text-lg rounded outline-0 border border-gray-100 px-3 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                placeholder="hello@projectleannation.com"
                onChange={(event) => {
                  this.setState({
                    email: event?.target?.value,
                  });
                }}
              />
            </div>
          </div>
          {!this.props.phone && (
            <div className="mt-4">
              <div className="relative">
                <label
                  htmlFor="phone"
                  className="absolute rounded-sm -top-[9px] left-2 inline-block bg-white px-1.5 text-[13px] font-medium text-gray-800 focus:text-orange-500"
                >
                  Phone
                  <span className="text-red-600 ml-[2px]">*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={this.state.phoneReadable}
                  required={true}
                  autoComplete="tel"
                  className="block w-full text-base sm:text-lg rounded outline-0 border border-gray-100 px-3 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 transition-all focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:leading-6"
                  placeholder="+1 (908) 867-5309"
                  onChange={(event: any) => {
                    let result = StringUtils.formatPhoneNumberWhileTyping(
                      event?.target?.value
                    );

                    this.setState({
                      phone: result?.value,
                      phoneReadable: result?.formatted,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-4">
          <IonGrid className="p-0">
            <IonRow className="ion-align-items-center">
              <IonCol size-xs={""} className="pl-0 pr-0">
                <div className="block sm:hidden mb-3 mt-1">
                  <p
                    className="small text-left mt-0 mb-1 pt-1"
                    style={{ fontSize: 13 }}
                  >
                    <img
                      src={stripeLogo}
                      style={{
                        height: 26,
                        position: "relative",
                        top: -1,
                        left: -2,
                      }}
                    ></img>
                    <span
                      style={{
                        position: "relative",
                        top: 1,
                        fontSize: 13,
                        paddingRight: 5,
                      }}
                      className="pl-2"
                    >
                      <IonIcon icon={lockClosed}></IonIcon>
                    </span>
                    Guaranteed&nbsp;
                    <span className="hidden sm:block">Safe &&nbsp;</span>
                    Secure Checkouts
                  </p>
                </div>
                <div className="hidden sm:block">
                  <p
                    className="small text-left mt-0 mb-1 pt-1 mb-sm-3"
                    style={{ fontSize: 15 }}
                  >
                    <img
                      src={stripeLogo}
                      style={{
                        height: 26,
                        position: "relative",
                        top: -1,
                        left: -2,
                      }}
                    ></img>
                    <span
                      style={{
                        position: "relative",
                        top: 1,
                        fontSize: 13,
                        paddingRight: 5,
                      }}
                      className="pl-2"
                    >
                      <IonIcon icon={lockClosed}></IonIcon>
                    </span>
                    Guaranteed Safe & Secure Checkouts
                  </p>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        {!this.state.paymentElementReady && (
          <>
            <IonSkeletonText
              className="mt-2"
              animated={true}
              style={{
                width: "100%",
                height: 320,
                marginTop: -4,
                marginBottom: -4,
                borderRadius: 6,
              }}
            ></IonSkeletonText>
          </>
        )}

        <PaymentElement
          className="mt-2"
          onReady={() => {}}
          onLoaderStart={() => {
            this.setState({
              paymentElementReady: true,
            });
          }}
          options={{
            layout: {
              type: "accordion",
            },

            wallets: {
              applePay: "never",
              googlePay: "never",
            },
          }}
        />

        <div className="mt-4 pt-2">
          {this.state.error && (
            <div className="mb-4 text-danger text-center">
              {this.state.error}
            </div>
          )}
        </div>
        <div
          id="completeSignup"
          data-attr="completeSignup"
          style={{ maxWidth: 500, margin: "auto" }}
        >
          <IonButton
            color="primary"
            size="large"
            className="pln-button-web"
            mode="ios"
            expand="block"
            disabled={
              !this.props.stripe ||
              !this.state.paymentElementReady ||
              !this.state.expressReady ||
              !this.state.email ||
              !this.state.name ||
              !this.state.phone ||
              this.state.submitting
            }
            type="submit"
          >
            {this.state.submitting ? (
              <IonSpinner name="dots"></IonSpinner>
            ) : (
              "Complete Signup"
            )}
          </IonButton>
          <p
            className="text-gray-900 text-center mt-3"
            style={{ fontSize: 13 }}
          >
            No payment will be collected today. You'll be charged on&nbsp;
            {<>{this.props.firstBillDate}</>} for your first order.
          </p>
        </div>
        <IonLoading
          isOpen={this.state.submitting && this.state.expressCheckout}
          onDidDismiss={() => {}}
          message={
            "Hang tight while we put the finishing touches on your new PLN plan!"
          }
        />
      </form>
    );
  }
}

class Checkout extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    coupon: null,
    idempotencyKey: StringUtils.uuid(),
  };

  boxModalRef: any = React.createRef();
  pageRef: any = React.createRef();

  googlePayRef: any = React.createRef();

  win: any = window;

  idleTimer: any = null;

  async loadStore() {
    this.setState({
      loadingStore: true,
    });

    let store = await StorageUtils.get("checkout_store");
    let lead = await StorageUtils.get("checkout_lead");

    if (store) {
      //console.log('STORE', store);

      if (this.state.plan?.planTypeID) {
        let allMeals: any =
          this.state.store?.menus?.meals[this.state.plan?.planTypeID]?.active
            ?.meals;

        this.setState({
          allMeals,
        });
      }

      this.setState({
        store,
      });
    }

    if (!store) {
      if (lead) {
        store = {
          _id: lead?.storeID,
        };
      }
    }

    // TODO: get from lead, fallback to store cache
    // if none, redirect to locations

    const storeID = store?._id;

    if (!this.state.storeLoaded) {
      API.getStoreByID(storeID)
        .then(
          async (data: any) => {
            const store = data?.data?.data?.store;

            this.setState({
              store,
              storeLoaded: true,
            });

            let existingCoupon: any = StorageUtils.get("checkout_coupon");

            if (existingCoupon) {
              if (
                existingCoupon?.defaultCoupon &&
                existingCoupon?.defaultStoreID != store?._id
              ) {
                this.setState({
                  coupon: null,
                  couponCode: null,
                });

                StorageUtils.set("checkout_coupon", null);

                PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, null);
              }
            }

            if (store?.operations?.defaultCoupon?.coupon) {
              let coupon = store?.operations?.defaultCoupon?.coupon;

              coupon.defaultCoupon = true;
              coupon.defaultStoreID = storeID;

              this.setState({
                coupon,
                couponCode: coupon?.code,
              });

              PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, {
                coupon: coupon,
                forceShow: true,
              });

              StorageUtils.set("checkout_coupon", coupon);
            }

            if (this.state.plan?.planTypeID) {
              let allMeals: any =
                this.state.store?.menus?.meals[this.state.plan?.planTypeID]
                  ?.active?.meals;

              this.setState({
                allMeals,
              });
            }

            await StorageUtils.set("checkout_store", store);
          },
          (e) => {}
        )
        .finally(() => {
          this.setState({
            loadingStore: false,
          });
        });
    }
  }

  async fetchCheckoutIntent(email: any) {
    //console.log("FETCH STRIPE", email);

    if (this.state.fetchingStripe) {
      return;
    }

    this.setState({
      fetchingStripe: true,
    });

    //console.log("FETCH");

    API.getStripeCheckoutIntent(email)
      .then(
        (data: any) => {
          //console.log(data.data);

          let opts: any = _.clone(stripeOptions);

          opts.clientSecret = data?.data?.data?.clientSecret;

          this.setState({
            stripeOptions: opts,
            stripeCustomerID: data.data.data.stripeCustomerID,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {});
  }

  async _loadLead(lead: any) {
    let email: any = "";

    if (!lead) {
      email = await StorageUtils.get("checkout_email");

      if (!email) {
        this.props.history.push("/start");

        return;
      }

      try {
        let res: any = await API.createOrUpdateLead(email);

        lead = res?.data?.data?.lead;

        if (lead) {
          // TODO: redirect here

          await StorageUtils.set("checkout_lead", lead);

          if (!lead?.storeID) {
            this.props.history.push("/start/plans");

            return;
          }

          if (lead?.cartState?.step == "box") {
            this.props.history.push("/start/box");

            return;
          }

          if (!lead?.cartState?.planID) {
            this.props.history.push("/start/plans");

            return;
          }
        } else {
          this.props.history.push("/start");

          return;
        }
      } catch (e) {
        console.log(e);
      }
    }

    this.setState(
      {
        lead,
      },
      () => {
        if (typeof window !== "undefined") {
          const win: any = window;

          if (!win?.STRIPE_OPTIONS || !win?.STRIPE_CUSTOMER_ID) {
            this.fetchCheckoutIntent(lead?.email);
          }
        } else {
          this.fetchCheckoutIntent(lead?.email);
        }

        this.loadStore();
      }
    );

    if (lead?.email) {
      this.setState({
        email: lead?.email,
      });
    }

    if (lead?.phone) {
      let formattedPhone: any = StringUtils.formatPhoneForServer(lead?.phone);

      this.setState({
        phone: lead?.phone,
        phoneReadable: formattedPhone,
      });
    }

    if (lead?.zipCode) {
      this.setState({
        zipCode: lead?.zipCode,
      });
    }

    if (lead?.name) {
      this.setState({
        name: lead?.name,
      });
    }

    if (lead?.coupon) {
      // console.log(lead?.coupon);

      this.setState({
        coupon: lead?.coupon,
      });
    }
  }

  ionViewWillLeave() {}

  async _loadStripe() {
    const stripe = await loadStripe(Constants.STRIPE_KEY);

    this.setState({
      stripe,
    });

    if (typeof window !== "undefined") {
      let win: any = window;

      if (win?.STRIPE_OPTIONS && win?.STRIPE_CUSTOMER_ID) {
        //console.log(win?.STRIPE_OPTIONS, win?.STRIPE_CUSTOMER_ID);

        this.setState({
          stripeOptions: win?.STRIPE_OPTIONS,
          stripeCustomerID: win?.STRIPE_CUSTOMER_ID,
        });
      }
    }
  }

  async _loadState() {
    this.loadFirstBillDate();

    let ffBox = await PostHogUtils.getFeatureFlag("checkoutMenuOptions");

    if (ffBox == "test") {
      this.setState({
        ffBox: true,
      });
    } else {
      this.setState({
        ffBox: false,
      });
    }

    StorageUtils.get("checkout_lead").then((lead: any) => {
      this._loadLead(lead);
    });

    StorageUtils.get("checkout_coupon").then((coupon: any) => {
      this.setState({ coupon, couponCode: coupon ? coupon?.code : "" });

      const win: any = window;
    });

    StorageUtils.get("checkout_referral").then((referral: any) => {
      this.setState({ referral, referralCode: referral ? referral?.code : "" });
    });

    StorageUtils.get("checkout_plan").then((plan: any) => {
      this.setState({
        plan,
      });

      if (plan) {
        SegmentUtils.track(
          "Checkout Started",
          {
            affiliation: "online",
            currency: "USD",
            store_id: plan?.storeID,
            value:
              ((plan?.unitPrice + (plan?.deliveryFee ? plan?.deliveryFee : 0)) /
                100) *
              6.2,

            products: [
              {
                product_id: plan?._id,
                quantity: 1,
                procurement: plan?.procurement,
                frequency: plan?.frequency,
                sku: plan?.code,
                category: "Meal Plans",
                category1: plan?.planType?.name,
                category2: plan?.procurement,
                category3: plan?.frequency,
                name: plan?.name,
                brand: "Project LeanNation",
                price:
                  (plan?.unitPrice +
                    (plan?.deliveryFee ? plan?.deliveryFee : 0)) /
                  100,
                google_location_id: this.state.store?.googleMapsPlaceID
                  ? this.state.store?.googleMapsPlaceID
                  : "",
              },
            ],
          },
          this.state.idempotencyKey
        );
      } else {
        const win: any = window;

        this.props.history.push("/start/plans");

        return;
      }
    });
  }

  componentDidMount() {
    this._loadState();

    this._loadStripe();

    /*PubSub.subscribe("wisepopsAbandonedCartApplyCoupon", () => {
      this.loadCoupon("WELCOME20");
    });*/

    PubSub.subscribe(Event.CHECKOUT.COUPON_UPDATED, (data: any) => {
      const coupon: any = data?.coupon;

      if (coupon?.referralProgram) {
        this.setState({
          referral: coupon,
          referralCode: coupon?.code,
        });
      } else {
        this.setState({ coupon, couponCode: coupon ? coupon?.code : "" });
      }
    });

    PubSub.subscribe(Event.CHECKOUT.LEAD_RESTORED, (lead: any) => {
      setTimeout(() => {
        this._loadState();
      }, 0);
    });
  }

  ionViewDidEnter() {
    this._loadState();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.state.name &&
      this.state.ccNumber?.length >= 14 &&
      this.state.expiryReadable?.length >= 5 &&
      this.state.cvc?.length >= 3 &&
      !this.state.paymentMethodEntered
    ) {
      this.setState(
        {
          paymentMethodEntered: StringUtils.uuid(),
        },
        () => {
          SegmentUtils.track("Payment Info Entered", {
            checkout_id:
              (this.state.lead?.userID
                ? this.state.lead?.userID
                : this.state.lead?._id) +
              "_cart_" +
              moment().format("YYYY-MM-DD"),
            payment_method: StringUtils.identifyCardType(this.state.ccNumber),
            step: 2,
          });
        }
      );
    }
  }

  getPrice(plan: any = null, coupon: any = null, getUnitPrice = false) {
    let unitPrice = plan?.unitPrice + (plan?.deliveryFee || 0);

    if (this.state.referral && !getUnitPrice) {
      unitPrice = unitPrice - this.state.referral?.flatDiscount;
    }

    if (!plan) {
      return "--";
    }

    let out = 0;

    out = unitPrice;

    if (!coupon) {
      if (getUnitPrice) {
        out = out / plan?.quantity;
      }

      return StringUtils.centsToCurrency(out);
    }

    if (coupon?.couponUsage == "tiered" && coupon?.couponTiers?.length) {
      let tier = coupon.couponTiers[0];

      if (tier.discountType == "Flat") {
        out = unitPrice - tier.flatDiscount;

        if (getUnitPrice) {
          out = out / plan?.quantity;
        }

        return StringUtils.centsToCurrency(out);
      }

      if (tier.discountType == "Percent") {
        out = unitPrice * (1 - tier.percentDiscount);

        if (getUnitPrice) {
          out = out / plan?.quantity;
        }

        return StringUtils.centsToCurrency(out);
      }

      if (getUnitPrice) {
        out = unitPrice / plan?.quantity;
      }

      return StringUtils.centsToCurrency(out);
    } else {
      let out = 0;

      if (coupon.discountType == "Flat") {
        out = unitPrice - coupon.flatDiscount;

        if (getUnitPrice) {
          out = out / plan?.quantity;
        }

        return StringUtils.centsToCurrency(out);
      }

      if (coupon.discountType == "Percent") {
        out = unitPrice * (1 - coupon.percentDiscount);

        if (getUnitPrice) {
          out = out / plan?.quantity;
        }

        return StringUtils.centsToCurrency(out);
      }

      if (getUnitPrice) {
        out = unitPrice / plan?.quantity;
      }

      return StringUtils.centsToCurrency(unitPrice);
    }
  }

  isPreorder() {
    if (this.state.store) {
      let firstBill = moment(
        this.state.store?.operations?.storeOpening?.firstBillDate
      );
      let now = moment();

      if (!now.isAfter(firstBill)) {
        return true;
      }
    }

    return false;
  }

  getNextPossibleOrderDate(asMoment = true) {
    let firstBillDate = moment(
      this.state.firstBillDate
        ? this.state.firstBillDate
        : this.state.store?.operations?.storeOpening?.firstBillDate
    );

    let storeFBD = moment(
      this.state.store?.operations?.storeOpening?.firstBillDate
    );

    let now = moment();

    now.tz("America/New_York");

    if (this.isPreorder() && firstBillDate.isBefore(storeFBD)) {
      firstBillDate = storeFBD;

      if (asMoment) {
        return firstBillDate;
      }

      return firstBillDate.format("MMMM DD, YYYY");
    }

    let next = moment();

    if (now.day() <= 3 && now.minutes() <= 59 && now.hours() <= 23) {
      next.day(6).startOf("day");
    } else {
      next.add(1, "week").day(6).startOf("day");
    }

    if (this.state.store?.operations?.blackoutDates?.length) {
      while (
        this.state.store?.operations?.blackoutDates.indexOf(
          next.format("YYYY-MM-DD")
        ) >= 0
      ) {
        next.add(1, "week");
      }
    }

    if (asMoment) {
      return next;
    }

    return next.format("MMMM DD, YYYY");
  }

  /**
   *
   * @param asMoment
   * @returns any
   */
  /**
   *
   * @param asMoment
   * @returns any
   */
  getFirstBillingDate(asMoment = false) {
    let firstBillDate = moment(
      this.state.firstBillDate
        ? this.state.firstBillDate
        : this.state.store?.operations?.storeOpening?.firstBillDate
    );

    let now = moment();

    now.tz("America/New_York");

    // Check to see if an override is present for the first bill date.
    if (this.state.firstBillDate) {
      let overrideMoment = moment(this.state.firstBillDate);
      let fbOverride = overrideMoment.isBefore(now);

      if (
        this.isPreorder() &&
        firstBillDate.isBefore(
          moment(this.state.store?.operations?.storeOpening?.firstBillDate)
        )
      ) {
        firstBillDate = moment(
          this.state.store?.operations?.storeOpening?.firstBillDate
        );

        if (asMoment) {
          return firstBillDate;
        }

        return firstBillDate.format("MMMM DD, YYYY");
      }

      if (this.state.store?.operations?.blackoutDates?.length) {
        while (
          this.state.store?.operations?.blackoutDates.indexOf(
            overrideMoment.format("YYYY-MM-DD")
          ) >= 0
        ) {
          overrideMoment.add(1, "week");
        }
      }

      // Check to see if the overrided date is before the first bill date for the store overall
      if (fbOverride) {
        StorageUtils.set("checkout_first_bill_date", "");
      } else {
        if (asMoment) {
          return overrideMoment;
        }

        return overrideMoment.format("MMMM DD, YYYY");
      }
    }

    if (this.isPreorder()) {
      if (asMoment) {
        return firstBillDate;
      }

      return firstBillDate.format("MMMM DD, YYYY");
    }

    let next = moment();

    if (now.day() <= 3 && now.minutes() <= 59 && now.hours() <= 23) {
      next.day(6).startOf("day");
    } else {
      next.add(1, "week").day(6).startOf("day");
    }

    if (this.state.store?.operations?.blackoutDates?.length) {
      while (
        this.state.store?.operations?.blackoutDates.indexOf(
          next.format("YYYY-MM-DD")
        ) >= 0
      ) {
        next.add(1, "week");
      }
    }

    if (asMoment) {
      return next;
    }

    return next.format("MMMM DD, YYYY");
  }

  getFirstBillingCutOffDate() {
    const fbd: any = this.getFirstBillingDate(true);

    return fbd.day(3).startOf("day");
  }

  getPlanType(categoryID: string) {
    if (!categoryID) {
      return;
    }

    let planType = _.findWhere(this.state.planTypes, { _id: categoryID });

    if (!planType) {
      return null;
    }

    planType = _.clone(planType);

    planType.name += " Meal Plans";

    return planType;
  }

  getMarketingName(store: any) {
    if (!store) {
      return "";
    }

    if (store?.type != "retail") {
      return "Delivered To You";
    }

    if (store?.displayCity && store?.displayState) {
      return `${store?.displayCity?.trim()}, ${store?.displayState?.trim()}`;
    }

    return `${store?.location?.address?.city?.trim()}, ${store?.location?.address?.state?.trim()}`;
  }
  getSelectedQuantity() {
    let quantity = 0;

    for (let i = 0; i < this.state.selectedMeals?.length; i++) {
      quantity += this.state.selectedMeals[i]?.quantity
        ? this.state.selectedMeals[i]?.quantity
        : 0;
    }

    return quantity;
  }

  getSelectedQuantityForMeal(meal: any) {
    let m = _.findWhere(this.state.selectedMeals, { _id: meal?._id });

    if (!m) {
      return 0;
    }

    return m?.quantity > 0 ? m?.quantity : 0;
  }

  /**
   * Sets the error message
   * @param error
   */
  setError(error: string) {
    this.setState({ error });

    setTimeout(() => {
      this.setState({ error: null });
    }, 5000);
  }

  setCouponError(error: string) {
    this.setState({ couponError: error });

    setTimeout(() => {
      this.setState({ couponError: null });
    }, 5000);
  }

  setReferralError(error: string) {
    this.setState({ referralError: error });

    setTimeout(() => {
      this.setState({ referralError: null });
    }, 5000);
  }

  async loadFirstBillDate() {
    let fbd = await StorageUtils.get("checkout_first_bill_date");

    if (fbd) {
      this.setState(
        {
          firstBillDate: fbd,
        },
        () => {
          this.setState({
            firstBillDate: this.getFirstBillingDate(true),
          });
        }
      );
    } else {
      this.setState({
        firstBillDate: this.getFirstBillingDate(true),
      });
    }
  }

  loadCoupon(code: any = "") {
    this.setState({
      couponSubmitting: true,
      couponError: null,
    });

    SegmentUtils.track("Coupon Entered", {
      cart_id:
        (this.state.lead?.userID
          ? this.state.lead?.userID
          : this.state.lead?._id) +
        "_cart_" +
        moment().format("YYYY-MM-DD"),
      coupon_id: code,
      type: "discount",
    });

    API.getCouponByCode(code)
      .then(
        (data: any) => {
          const coupon: any = data?.data?.data?.coupon;

          this.setState({
            coupon,
            couponCode: coupon?.code,
          });

          SegmentUtils.track("Coupon Applied", {
            cart_id:
              (this.state.lead?.userID
                ? this.state.lead?.userID
                : this.state.lead?._id) +
              "_cart_" +
              moment().format("YYYY-MM-DD"),
            coupon_id: coupon?.code,
            coupon_name: coupon?.name,
            coupon_terms: coupon?.terms ? coupon?.terms : "",
            type: "discount",
          });

          if (coupon) {
            StorageUtils.set("checkout_coupon", coupon);
            PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, { coupon });
          }
        },
        (e: any) => {
          console.error(e);

          SegmentUtils.track("Coupon Denied", {
            cart_id:
              (this.state.lead?.userID
                ? this.state.lead?.userID
                : this.state.lead?._id) +
              "_cart_" +
              moment().format("YYYY-MM-DD"),
            coupon_id: code,
            type: "discount",
            reason: "The coupon code you entered is invalid.",
          });

          this.setCouponError("The coupon code you entered is invalid.");
        }
      )
      .finally(() => {
        this.setState({
          couponSubmitting: false,
        });
      });
  }

  loadReferral(code: any = "") {
    if (code?.toLowerCase().indexOf("m-") != 0) {
      this.setReferralError("The referral code you entered is invalid.");
      return;
    }

    this.setState({
      referralSubmitting: true,
      referralError: null,
    });

    SegmentUtils.track("Coupon Entered", {
      cart_id:
        (this.state.lead?.userID
          ? this.state.lead?.userID
          : this.state.lead?._id) +
        "_cart_" +
        moment().format("YYYY-MM-DD"),
      coupon_id: code,
      type: "referral",
    });

    API.getCouponByCode(code?.toUpperCase())
      .then(
        async (data: any) => {
          const coupon = data?.data?.data?.coupon;

          if (!coupon?.referralProgram) {
            this.setReferralError("The referral code you entered is invalid.");
            return;
          }

          this.setState({
            referral: coupon,
            referralCode: coupon?.code,
          });

          SegmentUtils.track("Coupon Applied", {
            cart_id:
              (this.state.lead?.userID
                ? this.state.lead?.userID
                : this.state.lead?._id) +
              "_cart_" +
              moment().format("YYYY-MM-DD"),
            coupon_id: coupon?.code,
            coupon_name: coupon?.name,
            coupon_terms: coupon?.terms ? coupon?.terms : "",
            type: "referral",
          });

          if (coupon) {
            StorageUtils.set("checkout_referral", coupon);

            if (!this.state.coupon) {
              PubSub.publish(Event.CHECKOUT.COUPON_UPDATED, { coupon });
            }
          }
        },
        (e: any) => {
          console.error(e);

          SegmentUtils.track("Coupon Denied", {
            cart_id:
              (this.state.lead?.userID
                ? this.state.lead?.userID
                : this.state.lead?._id) +
              "_cart_" +
              moment().format("YYYY-MM-DD"),
            coupon_id: code,
            type: "referral",
            reason: "The referral code you entered is invalid.",
          });

          this.setReferralError("The referral code you entered is invalid.");
        }
      )
      .finally(() => {
        this.setState({
          referralSubmitting: false,
        });
      });
  }

  render() {
    return (
      <IonPage ref={this.pageRef}>
        <NavbarV2
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          onGoBack={async () => {
            this.props.history.push("/start/plans");
          }}
          activeStep={3}
          showBackButton={true}
          store={this.state.store}
        ></NavbarV2>
        <IonContent style={{ background: "var(--white)" }}>
          <div
            className="pb-lg-4"
            style={{
              background: "var(--white)",
            }}
          >
            <div className="pb-0 pb-lg-0">
              <div
                style={{
                  maxWidth: 1200,
                  margin: "auto",
                  overflow: "hidden",
                }}
              >
                <IonGrid className="pt-0 px-0">
                  <IonRow>
                    <IonCol
                      size-xs={12}
                      size-lg={8}
                      className="pt-0 px-0 pb-0 left-col"
                      style={{
                        background: "var(--white)",
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      <div
                        onClick={() => {
                          this.boxModalRef.current.present();

                          this.setState({
                            idleTime: 0,
                          });

                          SegmentUtils.track("Cart Viewed", {
                            cart_id:
                              (this.state.lead?.userID
                                ? this.state.lead?.userID
                                : this.state.lead?._id) +
                              "_cart_" +
                              moment().format("YYYY-MM-DD"),
                            products: [
                              {
                                product_id: this.state.plan?._id,
                                quantity: 1,
                                procurement: this.state.plan?.procurement,
                                frequency: this.state.plan?.frequency,
                                sku: this.state.plan?.code,
                                category: "Meal Plans",
                                name: this.state.plan?.name,
                                brand: "Project LeanNation",
                                category1: this.state.plan?.planType?.name,
                                category2: this.state.plan?.procurement,
                                category3: this.state.plan?.frequency,
                                price:
                                  (this.state.plan?.unitPrice +
                                    (this.state.plan?.deliveryFee
                                      ? this.state.plan?.deliveryFee
                                      : 0)) /
                                  100,
                              },
                            ],
                          });
                        }}
                        className="d-block d-lg-none px-3 py-2 cursor-pointer plan-summary-dropdown shadow-sm"
                        style={{
                          background: "white",
                          position: "fixed",

                          width: "100%",
                          zIndex: 10,
                          borderBottom: "1px solid var(--midlighter)",
                        }}
                      >
                        <IonRow className="ion-align-items-center">
                          <IonCol size-xs="">
                            <h3
                              className="text-dark mb-0 mt-0 text-capitalize font-weight-bold"
                              style={{ fontSize: 15 }}
                            >
                              Order Summary&nbsp;
                              <span style={{ position: "relative", top: 3 }}>
                                <IonIcon icon={chevronDown}></IonIcon>
                              </span>
                            </h3>
                          </IonCol>
                          <IonCol size-xs="auto">
                            <h1
                              className="text-dark mb-0 mt-0 font-weight-bold"
                              style={{ fontSize: 16 }}
                            >
                              {
                                <>
                                  <span
                                    className={`${
                                      this.state.coupon || this.state.referral
                                        ? "text-orange-600"
                                        : "text-dark"
                                    }`}
                                  >
                                    {this.getPrice(
                                      this.state.plan,
                                      this.state.coupon,
                                      false
                                    )}
                                  </span>
                                  {this.state.coupon || this.state.referral ? (
                                    <>
                                      <span
                                        className="pl-1 text-medium font-weight-500"
                                        style={{
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {this.getPrice(
                                          this.state.plan,
                                          null,
                                          false
                                        )}
                                      </span>
                                    </>
                                  ) : null}
                                </>
                              }
                            </h1>
                          </IonCol>
                        </IonRow>
                      </div>

                      <div className="mt-5 mt-lg-0 px-4 pt-4">
                        <div className="text-center  pb-3 pt-2 pt-lg-0 pb-lg-2">
                          <img
                            src={logo}
                            style={{ width: 160 }}
                            alt="Project LeanNation Logo"
                          ></img>
                        </div>

                        <div
                          className="mt-4"
                          style={{ position: "relative" }}
                        ></div>

                        <div className="mt-4">
                          {this.state.stripe && this.state.stripeOptions ? (
                            <Elements
                              stripe={this.state.stripe}
                              options={this.state.stripeOptions}
                            >
                              <ElementsConsumer>
                                {({ stripe, elements }) => (
                                  <StripeCheckoutForm
                                    lead={this.state.lead}
                                    plan={this.state.plan}
                                    selectedMeals={this.state.selectedMeals}
                                    referral={this.state.referral}
                                    coupon={this.state.coupon}
                                    stripeCustomerID={
                                      this.state.stripeCustomerID
                                    }
                                    history={this.props.history}
                                    email={this.state.email}
                                    phone={this.state.phone}
                                    stripe={stripe}
                                    elements={elements}
                                    firstBillDate={this.getFirstBillingDate(
                                      false
                                    )}
                                    store={this.state.store}
                                    onComplete={() => {
                                      console.log("complete");
                                    }}
                                  ></StripeCheckoutForm>
                                )}
                              </ElementsConsumer>
                            </Elements>
                          ) : (
                            <>
                              <IonSkeletonText
                                animated={true}
                                style={{
                                  width: "100%",
                                  height: 300,
                                  borderRadius: 6,
                                  marginTop: -4,
                                  marginBottom: -4,
                                }}
                              ></IonSkeletonText>
                            </>
                          )}
                        </div>
                      </div>
                    </IonCol>
                    <IonCol
                      className="d-none d-lg-flex"
                      size-xs={12}
                      size-lg={4}
                    >
                      <IonCard
                        mode="ios"
                        style={{
                          background: "white",
                          marginTop: "1rem",
                          marginRight: "0.5rem",
                          border: "1px solid var(--midlighter)",
                          boxShadow: "none",
                        }}
                        className="mb-0 ml-0"
                      >
                        <IonCardContent
                          className="p-0"
                          style={{ height: "100%" }}
                        >
                          <div style={{}} className="px-3 pt-3">
                            <IonRow className="ion-align-items-top">
                              <IonCol size-xs="12" className="px-0 pt-0">
                                <p
                                  className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                                  style={{ fontSize: 13 }}
                                >
                                  PLN+ Membership:
                                </p>
                              </IonCol>
                              <IonCol size-xs="" className="pl-0 pb-0 py-0">
                                <h1
                                  className="text-dark mb-0 mt-0 text-capitalize font-weight-bold"
                                  style={{ fontSize: 18 }}
                                >
                                  {
                                    <>
                                      {this.state.plan ? (
                                        <>
                                          {this.state.plan?.quantity} Meals /{" "}
                                          {this.state.plan?.frequency ==
                                          "weekly"
                                            ? "Week"
                                            : "2 Weeks"}
                                        </>
                                      ) : (
                                        <>--</>
                                      )}
                                    </>
                                  }
                                </h1>
                                <p
                                  className="text-dark text-capitalize mt-1 mb-0 font-weight-500"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.plan ? (
                                    <>
                                      {this.state.plan?.planType?.name?.trim()}
                                      &nbsp;Meal Plan
                                    </>
                                  ) : (
                                    "Loading Plan..."
                                  )}
                                </p>
                              </IonCol>
                              <IonCol
                                size-xs="auto"
                                className="pr-0 py-0 text-right"
                              >
                                <h1
                                  className="text-dark mb-0 mt-0 font-weight-bold"
                                  style={{ fontSize: 17 }}
                                >
                                  {
                                    <>
                                      <span
                                        className={`${
                                          this.state.coupon ||
                                          this.state.referral
                                            ? "text-primary"
                                            : "text-dark"
                                        }`}
                                      >
                                        {this.getPrice(
                                          this.state.plan,
                                          this.state.coupon,
                                          false
                                        )}
                                      </span>
                                      {this.state.coupon ||
                                      this.state.referral ? (
                                        <>
                                          <span
                                            className="pl-1 text-medium font-weight-500"
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {this.getPrice(
                                              this.state.plan,
                                              null,
                                              false
                                            )}
                                          </span>
                                        </>
                                      ) : null}
                                    </>
                                  }
                                </h1>
                                {(this.state.coupon &&
                                  this.state.coupon
                                    ?.removeFromSubscriptionAfterUses !== -1) ||
                                this.state.referral ? (
                                  <p
                                    className="text-medium text-lowercase text-right mt-1 mb-0 font-weight-500"
                                    style={{ fontSize: 13 }}
                                  >
                                    {this.getPrice(this.state.plan, null)} after
                                    discounts
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </IonCol>
                            </IonRow>
                          </div>
                          <div
                            style={{
                              borderBottom: "1px solid var(--midlighter)",
                            }}
                            className="p-3"
                          >
                            <IonRow className="ion-align-items-top">
                              <IonCol size-xs="" className="pl-0 pb-0 py-0">
                                <h1
                                  className="text-dark mb-0 mt-0 text-capitalize font-weight-bold"
                                  style={{ fontSize: 16 }}
                                >
                                  Due Today:
                                </h1>
                              </IonCol>
                              <IonCol className="pr-0 py-0 text-right">
                                <h1
                                  className="text-dark mb-0 mt-0 font-weight-bold"
                                  style={{ fontSize: 16 }}
                                >
                                  $0.00
                                </h1>
                              </IonCol>
                            </IonRow>
                          </div>
                          <div
                            style={{
                              borderBottom: "1px solid var(--midlighter)",
                            }}
                            className="p-3"
                          >
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="" className="pl-0 pb-0 py-0">
                                <p
                                  className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.plan ? (
                                    <span className="text-capitalize">
                                      First{" "}
                                      {this.state.plan?.procurement
                                        ?.trim()
                                        ?.replace("shipping", "shipment")}{" "}
                                      {this.state.plan?.procurement?.trim() ==
                                      "pickup"
                                        ? "@"
                                        : "from"}{" "}
                                      {this.state.store?.name
                                        ?.replace("Project LeanNation", "PLN")
                                        .trim()}
                                      :
                                    </span>
                                  ) : (
                                    <>First Order Ready:</>
                                  )}
                                </p>
                                <p
                                  style={{ fontSize: 18 }}
                                  className="text-dark m-0 font-weight-bold"
                                >
                                  {<>{this.getFirstBillingDate(false)}</>}
                                </p>
                                {
                                  // @ts-ignore
                                  typeof this.getFirstBillingDate(true) ==
                                    "object" &&
                                  // @ts-ignore
                                  typeof (
                                    // @ts-ignore
                                    this.getFirstBillingDate(true)?.isAfter
                                  ) == "function" &&
                                  //@ts-ignore

                                  this.getFirstBillingDate(true).isAfter(
                                    this.getNextPossibleOrderDate()
                                  ) ? (
                                    <>
                                      <p
                                        onClick={() => {
                                          StorageUtils.set(
                                            "checkout_first_bill_date",
                                            ""
                                          ).then(() => {
                                            this.setState({
                                              firstBillDate: null,
                                            });
                                          });
                                        }}
                                        style={{ fontSize: 14 }}
                                        className="text-primary underline cursor-pointer m-0 small font-weight-bold"
                                      >
                                        Get It Sooner On:{" "}
                                        {
                                          // @ts-ignore
                                          this.getNextPossibleOrderDate()?.format(
                                            "MMM Do, YYYY"
                                          )
                                        }
                                      </p>
                                    </>
                                  ) : null
                                }
                              </IonCol>
                            </IonRow>
                          </div>
                          <div
                            style={{
                              borderBottom: "1px solid var(--midlighter)",
                            }}
                            className="p-3"
                          >
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                                <p
                                  className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.coupon ? (
                                    <>Discount Applied</>
                                  ) : (
                                    <>Discount</>
                                  )}
                                </p>
                              </IonCol>
                              <IonCol size-xs="12" className="px-0 py-0">
                                {this.state.coupon ? (
                                  <>
                                    <div
                                      className="p-2 mt-2"
                                      style={{
                                        border: "1px solid var(--midlighter)",
                                        background: "var(--ultralight)",
                                        borderRadius: 6,
                                      }}
                                    >
                                      <IonRow className="ion-align-items-center">
                                        <IonCol
                                          size-xs=""
                                          className="pl-0 py-0"
                                        >
                                          <p
                                            className="text-dark font-weight-bold text-uppercase m-0"
                                            style={{ fontSize: 16 }}
                                          >
                                            {this.state.coupon?.code}
                                          </p>
                                          <p
                                            className="text-dark font-weight-normal m-0"
                                            style={{ fontSize: 12 }}
                                          >
                                            {StringUtils.generateCouponValueString(
                                              this.state.coupon,
                                              true
                                            )}
                                          </p>
                                        </IonCol>
                                        <IonCol
                                          size-xs="auto"
                                          className="pr-0 py-0"
                                        >
                                          <IonButton
                                            color="danger"
                                            fill="clear"
                                            size="small"
                                            className="pln-button-web-link"
                                            onClick={() => {
                                              SegmentUtils.track(
                                                "Coupon Removed",
                                                {
                                                  cart_id:
                                                    (this.state.lead?.userID
                                                      ? this.state.lead?.userID
                                                      : this.state.lead?._id) +
                                                    "_cart_" +
                                                    moment().format(
                                                      "YYYY-MM-DD"
                                                    ),
                                                  coupon_id:
                                                    this.state.coupon?.code,
                                                  coupon_name:
                                                    this.state.coupon?.name,
                                                  coupon_terms: this.state
                                                    .coupon?.terms
                                                    ? this.state.coupon?.terms
                                                    : "",
                                                  type: "discount",
                                                }
                                              );

                                              this.setState({
                                                coupon: null,
                                              });

                                              StorageUtils.set(
                                                "checkout_coupon",
                                                null
                                              );
                                              PubSub.publish(
                                                Event.CHECKOUT.COUPON_UPDATED,
                                                null
                                              );
                                            }}
                                          >
                                            <IonIcon
                                              slot="icon-only"
                                              icon={close}
                                            />
                                          </IonButton>
                                        </IonCol>
                                      </IonRow>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <IonRow className="ion-align-items-center mt-2">
                                      <IonCol size-xs="" className="pl-0 py-0">
                                        <IonItem
                                          color="primary"
                                          className={`pln-input-web x-small`}
                                          mode="md"
                                          fill="outline"
                                        >
                                          <IonInput
                                            autocapitalize="none"
                                            inputMode="text"
                                            color="dark"
                                            name="couponCode"
                                            value={this.state.couponCode}
                                            //pattern="email"
                                            placeholder="Discount Code"
                                            onIonChange={(event: any) => {
                                              this.setState({
                                                idleTime: 0,
                                                couponCode:
                                                  event?.detail?.value,
                                              });
                                            }}
                                          ></IonInput>
                                        </IonItem>
                                      </IonCol>
                                      <IonCol
                                        size-xs="auto"
                                        className="pr-0 py-0"
                                      >
                                        <IonButton
                                          color="primary"
                                          size="small"
                                          fill="clear"
                                          className="pln-button-web-link"
                                          disabled={
                                            this.state.couponSubmitting ||
                                            !this.state.couponCode
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();

                                            this.loadCoupon(
                                              this.state.couponCode
                                            );
                                          }}
                                        >
                                          {this.state.couponSubmitting ? (
                                            <IonSpinner name="dots"></IonSpinner>
                                          ) : (
                                            <IonIcon
                                              slot="icon-only"
                                              icon={arrowForwardCircle}
                                            />
                                          )}
                                        </IonButton>
                                      </IonCol>
                                      {this.state.couponError ? (
                                        <IonCol
                                          size-xs="12"
                                          className="px-0 pt-1 pb-0"
                                        >
                                          <div
                                            className="font-weight-500"
                                            style={{
                                              color: "var(--danger)",
                                              fontSize: 14,
                                            }}
                                          >
                                            {this.state.couponError}
                                          </div>
                                        </IonCol>
                                      ) : null}
                                    </IonRow>
                                  </>
                                )}
                              </IonCol>
                            </IonRow>
                          </div>
                          <div
                            style={{
                              borderBottom: "1px solid var(--midlighter)",
                            }}
                            className="p-3"
                          >
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                                <p
                                  className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.referral ? (
                                    <>Referral Applied:</>
                                  ) : (
                                    <>
                                      Referred By A Friend? Enter Their Referral
                                      Code:
                                    </>
                                  )}
                                </p>
                              </IonCol>
                              <IonCol size-xs="12" className="px-0 py-0">
                                {this.state.referral ? (
                                  <>
                                    <div
                                      className="p-2 mt-2"
                                      style={{
                                        border: "1px solid var(--midlighter)",
                                        background: "var(--ultralight)",
                                        borderRadius: 6,
                                      }}
                                    >
                                      <IonRow className="ion-align-items-center">
                                        <IonCol
                                          size-xs=""
                                          className="pl-0 py-0"
                                        >
                                          <p
                                            className="text-dark font-weight-bold text-uppercase m-0"
                                            style={{ fontSize: 16 }}
                                          >
                                            {this.state.referral?.name}
                                          </p>
                                          <p
                                            className="text-dark font-weight-normal m-0"
                                            style={{ fontSize: 12 }}
                                          >
                                            {StringUtils.generateCouponValueString(
                                              this.state.referral,
                                              true
                                            )}
                                          </p>
                                        </IonCol>
                                        <IonCol
                                          size-xs="auto"
                                          className="pr-0 py-0"
                                        >
                                          <IonButton
                                            color="danger"
                                            fill="clear"
                                            size="small"
                                            className="pln-button-web-link"
                                            onClick={() => {
                                              SegmentUtils.track(
                                                "Coupon Removed",
                                                {
                                                  cart_id:
                                                    (this.state.lead?.userID
                                                      ? this.state.lead?.userID
                                                      : this.state.lead?._id) +
                                                    "_cart_" +
                                                    moment().format(
                                                      "YYYY-MM-DD"
                                                    ),
                                                  coupon_id:
                                                    this.state.referral?.code,
                                                  coupon_name:
                                                    this.state.referral?.name,
                                                  coupon_terms: this.state
                                                    .referral?.terms
                                                    ? this.state.referral?.terms
                                                    : "",
                                                  type: "referral",
                                                }
                                              );

                                              this.setState({
                                                referral: null,
                                              });

                                              StorageUtils.set(
                                                "checkout_referral",
                                                null
                                              );

                                              if (!this.state.coupon) {
                                                PubSub.publish(
                                                  Event.CHECKOUT.COUPON_UPDATED,
                                                  null
                                                );
                                              }
                                            }}
                                          >
                                            <IonIcon
                                              slot="icon-only"
                                              icon={close}
                                            />
                                          </IonButton>
                                        </IonCol>
                                      </IonRow>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <IonRow className="ion-align-items-center mt-2">
                                      <IonCol size-xs="" className="pl-0 py-0">
                                        <IonItem
                                          color="primary"
                                          className={`pln-input-web x-small`}
                                          mode="md"
                                          fill="outline"
                                        >
                                          <IonInput
                                            autocapitalize="none"
                                            inputMode="text"
                                            color="dark"
                                            name="referralCode"
                                            value={this.state.referralCode}
                                            //pattern="email"
                                            placeholder="M-XXXXXX"
                                            onIonChange={(event: any) => {
                                              this.setState({
                                                idleTime: 0,
                                                referralCode:
                                                  event?.detail?.value,
                                              });
                                            }}
                                          ></IonInput>
                                        </IonItem>
                                      </IonCol>
                                      <IonCol
                                        size-xs="auto"
                                        className="pr-0 py-0"
                                      >
                                        <IonButton
                                          color="primary"
                                          size="small"
                                          fill="clear"
                                          className="pln-button-web-link"
                                          disabled={
                                            this.state.referralSubmitting ||
                                            !this.state.referralCode
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();

                                            this.loadReferral(
                                              this.state.referralCode
                                            );
                                          }}
                                        >
                                          {this.state.referralSubmitting ? (
                                            <IonSpinner name="dots"></IonSpinner>
                                          ) : (
                                            <IonIcon
                                              slot="icon-only"
                                              icon={arrowForwardCircle}
                                            />
                                          )}
                                        </IonButton>
                                      </IonCol>
                                      {this.state.referralError ? (
                                        <IonCol
                                          size-xs="12"
                                          className="px-0 pt-1 pb-0"
                                        >
                                          <div
                                            className="font-weight-500"
                                            style={{
                                              color: "var(--danger)",
                                              fontSize: 14,
                                            }}
                                          >
                                            {this.state.referralError}
                                          </div>
                                        </IonCol>
                                      ) : null}
                                    </IonRow>
                                  </>
                                )}
                              </IonCol>
                            </IonRow>
                          </div>
                          <div className="pt-3 px-3 mb-1">
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                                <h1
                                  className="text-dark mb-1 mt-0 text-capitalize font-weight-bold"
                                  style={{ fontSize: 18 }}
                                >
                                  Meals On{" "}
                                  {this.state.store
                                    ? `The ${this.state.store?.name
                                        ?.replace("Project LeanNation", "PLN")
                                        ?.trim()}`
                                    : "Our"}{" "}
                                  Menu:
                                </h1>
                                <p
                                  className="text-dark text-base mt-1 mb-0"
                                  style={{ lineHeight: 1.5 }}
                                >
                                  You can choose from any of these meals for
                                  your first order after you finish signing up.
                                </p>
                              </IonCol>
                            </IonRow>
                          </div>
                          <div>
                            {this.state.allMeals?.length ? (
                              <>
                                {this.state.allMeals?.map(
                                  (meal: any, i: number) => (
                                    <div
                                      key={meal?._id}
                                      className="mx-3 my-3"
                                      style={{
                                        borderRadius: 6,
                                        overflow: "hidden",
                                        border: "1px solid var(--lighter)",
                                      }}
                                    >
                                      <MealOptionCard
                                        meal={meal}
                                        mobile={true}
                                        compact={true}
                                        modalID="webapp-checkout-box"
                                      ></MealOptionCard>
                                    </div>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <div
                                  className="border mt-3 mb-3 mx-3 p-3 text-center d-flex ion-align-items-center"
                                  style={{
                                    borderRadius: 6,
                                    height: "calc(100% - 2rem)",
                                  }}
                                >
                                  <div>
                                    <h1
                                      style={{ fontSize: 18 }}
                                      className="text-dark text-center mb-2 font-weight-500"
                                    >
                                      Loading Your Menu
                                    </h1>
                                    <p className="m-0 text-center text-dark">
                                      Hang tight while we're loading your menu.
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            {/** Desktop View */}
          </div>
          <IonModal
            ref={this.boxModalRef}
            trigger="meal-detailmodal"
            canDismiss={true}
            mode="ios"
          >
            <IonHeader>
              <IonToolbar
                className="box-modal-toolbar-web"
                style={{ borderBottom: "none" }}
              >
                <IonTitle>Plan Summary</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    color="dark"
                    onClick={() => {
                      this.boxModalRef?.current?.dismiss();

                      this.setState({
                        meal: null,
                        idleTime: 0,
                      });
                    }}
                  >
                    <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div style={{}} className="px-3 pt-3">
                <IonRow className="ion-align-items-top">
                  <IonCol size-xs="12" className="px-0 pt-0">
                    <p
                      className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                      style={{ fontSize: 13 }}
                    >
                      PLN+ Membership:
                    </p>
                  </IonCol>
                  <IonCol size-xs="" className="pl-0 pb-0 py-0">
                    <h1
                      className="text-dark mb-0 mt-0 text-capitalize font-weight-bold"
                      style={{ fontSize: 18 }}
                    >
                      {
                        <>
                          {this.state.plan ? (
                            <>
                              {this.state.plan?.quantity} Meals /{" "}
                              {this.state.plan?.frequency == "weekly"
                                ? "Week"
                                : "2 Weeks"}
                            </>
                          ) : (
                            <>--</>
                          )}
                        </>
                      }
                    </h1>
                    <p
                      className="text-dark text-capitalize mt-1 mb-0 font-weight-500"
                      style={{ fontSize: 13 }}
                    >
                      {this.state.plan ? (
                        <>
                          {this.state.plan?.planType?.name?.trim()}
                          &nbsp;Meal Plan
                        </>
                      ) : (
                        "Loading Plan..."
                      )}
                    </p>
                  </IonCol>
                  <IonCol className="pr-0 py-0 text-right">
                    <h1
                      className="text-dark mb-0 mt-0 font-weight-bold"
                      style={{ fontSize: 17 }}
                    >
                      {
                        <>
                          <span
                            className={`${
                              this.state.coupon || this.state.referral
                                ? "text-orange-600"
                                : "text-dark"
                            }`}
                          >
                            {this.getPrice(this.state.plan, this.state.coupon)}
                          </span>
                          {this.state.coupon || this.state.referral ? (
                            <>
                              <span
                                className="pl-1 text-medium font-weight-500"
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {this.getPrice(this.state.plan, null)}
                              </span>
                            </>
                          ) : null}
                        </>
                      }
                    </h1>
                    {(this.state.coupon &&
                      this.state.coupon?.removeFromSubscriptionAfterUses !==
                        -1) ||
                    this.state.referral ? (
                      <p
                        className="text-medium text-lowercase text-right mt-1 mb-0 font-weight-500"
                        style={{ fontSize: 13 }}
                      >
                        {this.getPrice(this.state.plan, null)} after discounts
                      </p>
                    ) : null}
                  </IonCol>
                </IonRow>
              </div>
              <div
                style={{
                  borderBottom: "1px solid var(--midlighter)",
                }}
                className="p-3"
              >
                <IonRow className="ion-align-items-top">
                  <IonCol size-xs="" className="pl-0 pb-0 py-0">
                    <h1
                      className="text-dark mb-0 mt-0 text-capitalize font-weight-bold"
                      style={{ fontSize: 16 }}
                    >
                      Due Today:
                    </h1>
                  </IonCol>
                  <IonCol className="pr-0 py-0 text-right">
                    <h1
                      className="text-dark mb-0 mt-0 font-weight-bold"
                      style={{ fontSize: 16 }}
                    >
                      $0.00
                    </h1>
                  </IonCol>
                </IonRow>
              </div>
              <div
                style={{
                  borderBottom: "1px solid var(--midlighter)",
                }}
                className="p-3"
              >
                <IonRow className="ion-align-items-center">
                  <IonCol size-xs="" className="pl-0 pb-0 py-0">
                    <p
                      className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                      style={{ fontSize: 13 }}
                    >
                      {this.state.plan ? (
                        <span className="text-capitalize">
                          First{" "}
                          {this.state.plan?.procurement
                            ?.trim()
                            ?.replace("shipping", "shipment")}{" "}
                          {this.state.plan?.procurement?.trim() == "pickup"
                            ? "@"
                            : "from"}{" "}
                          {this.state.store?.name
                            ?.replace("Project LeanNation", "PLN")
                            .trim()}
                          :
                        </span>
                      ) : (
                        <>First Order Ready:</>
                      )}
                    </p>
                    <p
                      style={{ fontSize: 18 }}
                      className="text-dark m-0 font-weight-bold"
                    >
                      {<>{this.getFirstBillingDate(false)}</>}
                    </p>
                    {
                      // @ts-ignore
                      typeof this.getFirstBillingDate(true) == "object" &&
                      // @ts-ignore
                      typeof (
                        // @ts-ignore
                        this.getFirstBillingDate(true)?.isAfter
                      ) == "function" &&
                      //@ts-ignore

                      this.getFirstBillingDate(true).isAfter(
                        this.getNextPossibleOrderDate()
                      ) ? (
                        <>
                          <p
                            onClick={() => {
                              StorageUtils.set(
                                "checkout_first_bill_date",
                                ""
                              ).then(() => {
                                this.setState({
                                  firstBillDate: null,
                                });
                              });
                            }}
                            style={{ fontSize: 14 }}
                            className="text-primary underline cursor-pointer m-0 small font-weight-bold"
                          >
                            Get It Sooner On:{" "}
                            {
                              // @ts-ignore
                              this.getNextPossibleOrderDate()?.format(
                                "MMM Do, YYYY"
                              )
                            }
                          </p>
                        </>
                      ) : null
                    }
                  </IonCol>
                </IonRow>
              </div>
              <div
                style={{
                  borderBottom: "1px solid var(--midlighter)",
                }}
                className="p-3"
              >
                <IonRow className="ion-align-items-center">
                  <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                    <p
                      className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                      style={{ fontSize: 13 }}
                    >
                      {this.state.coupon ? (
                        <>Discount Applied</>
                      ) : (
                        <>Discount</>
                      )}
                    </p>
                  </IonCol>
                  <IonCol size-xs="12" className="px-0 py-0">
                    {this.state.coupon ? (
                      <>
                        <div
                          className="p-2 mt-2"
                          style={{
                            border: "1px solid var(--midlighter)",
                            background: "var(--ultralight)",
                            borderRadius: 6,
                          }}
                        >
                          <IonRow className="ion-align-items-center">
                            <IonCol size-xs="" className="pl-0 py-0">
                              <p
                                className="text-dark font-weight-bold text-uppercase m-0"
                                style={{ fontSize: 16 }}
                              >
                                {this.state.coupon?.code}
                              </p>
                              <p
                                className="text-dark font-weight-normal m-0"
                                style={{ fontSize: 12 }}
                              >
                                {StringUtils.generateCouponValueString(
                                  this.state.coupon,
                                  true
                                )}
                              </p>
                            </IonCol>
                            <IonCol size-xs="auto" className="pr-0 py-0">
                              <IonButton
                                color="danger"
                                fill="clear"
                                size="small"
                                className="pln-button-web-link"
                                onClick={() => {
                                  this.setState({
                                    coupon: null,
                                  });

                                  StorageUtils.set("checkout_coupon", null);
                                  PubSub.publish(
                                    Event.CHECKOUT.COUPON_UPDATED,
                                    null
                                  );
                                }}
                              >
                                <IonIcon slot="icon-only" icon={close} />
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </div>
                      </>
                    ) : (
                      <>
                        <IonRow className="ion-align-items-center mt-2">
                          <IonCol size-xs="" className="pl-0 py-0">
                            <IonItem
                              color="primary"
                              className={`pln-input-web x-small`}
                              mode="md"
                              fill="outline"
                            >
                              <IonInput
                                autocapitalize="none"
                                inputMode="text"
                                color="dark"
                                name="couponCode"
                                value={this.state.couponCode}
                                //pattern="email"
                                placeholder="Discount Code"
                                onIonChange={(event: any) => {
                                  this.setState({
                                    idleTime: 0,
                                    couponCode: event?.detail?.value,
                                  });
                                }}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                          <IonCol size-xs="auto" className="pr-0 py-0">
                            <IonButton
                              color="primary"
                              size="small"
                              fill="clear"
                              className="pln-button-web-link"
                              disabled={
                                this.state.couponSubmitting ||
                                !this.state.couponCode
                              }
                              onClick={(e) => {
                                e.preventDefault();

                                this.loadCoupon(this.state.couponCode);
                              }}
                            >
                              {this.state.couponSubmitting ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <IonIcon
                                  slot="icon-only"
                                  icon={arrowForwardCircle}
                                />
                              )}
                            </IonButton>
                          </IonCol>
                          {this.state.couponError ? (
                            <IonCol size-xs="12" className="px-0 pt-1 pb-0">
                              <div
                                className="font-weight-500"
                                style={{
                                  color: "var(--danger)",
                                  fontSize: 14,
                                }}
                              >
                                {this.state.couponError}
                              </div>
                            </IonCol>
                          ) : null}
                        </IonRow>
                      </>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              <div
                style={{
                  borderBottom: "1px solid var(--midlighter)",
                }}
                className="p-3"
              >
                <IonRow className="ion-align-items-center">
                  <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                    <p
                      className="text-dark text-capitalize mt-0 mb-1 font-weight-500"
                      style={{ fontSize: 13 }}
                    >
                      {this.state.referral ? (
                        <>Referral Applied:</>
                      ) : (
                        <>Referred By A Friend? Enter Their Referral Code:</>
                      )}
                    </p>
                  </IonCol>
                  <IonCol size-xs="12" className="px-0 py-0">
                    {this.state.referral ? (
                      <>
                        <div
                          className="p-2 mt-2"
                          style={{
                            border: "1px solid var(--midlighter)",
                            background: "var(--ultralight)",
                            borderRadius: 6,
                          }}
                        >
                          <IonRow className="ion-align-items-center">
                            <IonCol size-xs="" className="pl-0 py-0">
                              <p
                                className="text-dark font-weight-bold text-uppercase m-0"
                                style={{ fontSize: 16 }}
                              >
                                {this.state.referral?.name}
                              </p>
                              <p
                                className="text-dark font-weight-normal m-0"
                                style={{ fontSize: 12 }}
                              >
                                {StringUtils.generateCouponValueString(
                                  this.state.referral,
                                  true
                                )}
                              </p>
                            </IonCol>
                            <IonCol size-xs="auto" className="pr-0 py-0">
                              <IonButton
                                color="danger"
                                fill="clear"
                                size="small"
                                className="pln-button-web-link"
                                onClick={() => {
                                  this.setState({
                                    referral: null,
                                  });

                                  StorageUtils.set("checkout_referral", null);
                                }}
                              >
                                <IonIcon slot="icon-only" icon={close} />
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </div>
                      </>
                    ) : (
                      <>
                        <IonRow className="ion-align-items-center mt-2">
                          <IonCol size-xs="" className="pl-0 py-0">
                            <IonItem
                              color="primary"
                              className={`pln-input-web x-small`}
                              mode="md"
                              fill="outline"
                            >
                              <IonInput
                                autocapitalize="none"
                                inputMode="text"
                                color="dark"
                                name="referralCode"
                                value={this.state.referralCode}
                                //pattern="email"
                                placeholder="M-XXXXXX"
                                onIonChange={(event: any) => {
                                  this.setState({
                                    idleTime: 0,
                                    referralCode: event?.detail?.value,
                                  });
                                }}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                          <IonCol size-xs="auto" className="pr-0 py-0">
                            <IonButton
                              color="primary"
                              size="small"
                              fill="clear"
                              className="pln-button-web-link"
                              disabled={
                                this.state.referralSubmitting ||
                                !this.state.referralCode
                              }
                              onClick={(e) => {
                                e.preventDefault();

                                this.loadReferral(this.state.referralCode);
                              }}
                            >
                              {this.state.referralSubmitting ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <IonIcon
                                  slot="icon-only"
                                  icon={arrowForwardCircle}
                                />
                              )}
                            </IonButton>
                          </IonCol>
                          {this.state.referralError ? (
                            <IonCol size-xs="12" className="px-0 pt-1 pb-0">
                              <div
                                className="font-weight-500"
                                style={{
                                  color: "var(--danger)",
                                  fontSize: 14,
                                }}
                              >
                                {this.state.referralError}
                              </div>
                            </IonCol>
                          ) : null}
                        </IonRow>
                      </>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              <div style={{}} className="px-3 pt-3 pb-1">
                <IonRow className="ion-align-items-center">
                  <IonCol size-xs="12" className="pl-0 pb-0 py-0">
                    <h1
                      className="text-dark mb-1 mt-0 text-capitalize font-weight-bold"
                      style={{ fontSize: 18 }}
                    >
                      Meals On{" "}
                      {this.state.store
                        ? `The ${this.state.store?.name
                            ?.replace("Project LeanNation", "PLN")
                            ?.trim()}`
                        : "Our"}{" "}
                      Menu:
                    </h1>
                    <p
                      className="text-dark text-base mt-1 mb-0"
                      style={{ lineHeight: 1.5 }}
                    >
                      You can choose from any of these meals for your first
                      order after you finish signing up.
                    </p>
                  </IonCol>
                </IonRow>
              </div>
              <div>
                {this.state.allMeals?.length ? (
                  <>
                    {this.state.allMeals?.map((meal: any, i: number) => (
                      <div key={meal?._id}>
                        <div
                          className="mx-3 my-3"
                          style={{
                            borderRadius: 6,
                            overflow: "hidden",
                            border: "1px solid var(--lighter)",
                          }}
                        >
                          <MealOptionCard
                            meal={meal}
                            mobile={true}
                            compact={true}
                            modalID="webapp-checkout-box"
                          ></MealOptionCard>
                        </div>
                        {i == this.state.allMeals?.length - 1 && (
                          <>
                            <div className="pb-4" />
                          </>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div
                      className="border mt-3 mb-3 mx-3 p-3 text-center d-flex ion-align-items-center"
                      style={{
                        borderRadius: 6,
                        height: "calc(100% - 2rem)",
                      }}
                    >
                      <div>
                        <h1
                          style={{ fontSize: 18 }}
                          className="text-dark mb-2 font-weight-500"
                        >
                          Loading Your Menu
                        </h1>
                        <p className="m-0 text-dark">
                          Hang tight while we're loading your menu.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </IonContent>
          </IonModal>
          <Footer bgWhite={true}></Footer>
          <MealDetailModal
            id="webapp-checkout-box"
            presentingElement={this.pageRef}
          ></MealDetailModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Checkout);
